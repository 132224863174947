import React from "react";

const ToolboxPdfCard = (props) => {
  const showCta = props.buttonHref && props.buttonHref.trim() !== "";

  return (
    <div className="toolbox-pdf-section-card">
      <div className="toolbox-pdf-section-card-image-container">
        <img
          className="toolbox-pdf-section-card-image"
          src={props.icon}
          alt=""
        />
      </div>
      <div className="toolbox-pdf-section-card-title">{props.title}</div>
      <div
        className="toolbox-pdf-section-card-description"
        dangerouslySetInnerHTML={{ __html: props.description }}
      />
      {showCta && (
        <div className="toolbox-pdf-section-card-cta-container">
          <a
            href={props.buttonHref}
            download
            target="blank"
            className="toolbox-pdf-section-card-cta-button"
          >
            {props.buttonLabel}
          </a>
        </div>
      )}
    </div>
  );
};

export default ToolboxPdfCard;
